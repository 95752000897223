.ft-table {
    height: 100%;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
}

.ft-table-content {
    display: grid;
    overflow-y: auto;
    overflow-x: hidden;
    align-content: baseline;
}

.ft-table-content .spin-element {
    top: 50%;
    left: 50%;
    z-index: 2042;
    position: fixed;
    color: rgb(255, 255, 255);
}

.sticky-table-footer {
    background-color: white;
    position: sticky;
    bottom: 0;
    z-index: 10;
    margin-top: 10px;
}

.sticky-table-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    box-shadow: 0px 1px #e7eaec;
}

/* for tables with in tables */
table table .sticky-table-header {
    z-index: 9;
}

.ft-row-details-content {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-color: rgb(231, 234, 236);
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.justify-content-center {
    text-align: center;
}

.justify-content-flex-start {
    text-align: start;
}

.justify-content-flex-end {
    text-align: end;
}

.row-clickable {
    cursor: pointer;
} 

a.btn.disabled {
    pointer-events: auto;
    cursor: not-allowed;
}

.fa-chevron-down {
    transition: all 0.3s ease;
}

.accordion-toggle.collapsed .fa-chevron-down {
    transform: rotate(-90deg);
}

.single-table-row {
    background-color: #ffffff !important;
}

.single-table-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}