@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #ededed;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
